

import React, { useState, useEffect } from "react";
import { SubmitBtn } from "../../Components/Card/Card";
import { useSelector, useDispatch } from "react-redux";
import { saveBlogImages } from "../../redux/action/blogAction";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import FormLoader from "../../Components/Loader/formLoader";

const BlogSection: React.FC = () => {
    const dispatch = useDispatch();
    const Snackbar = useSnackbar();
    const [sections, setSections] = useState([{ id: 1, imageUrl: "", title: "", description: "" }]);
    const { blogDetails } = useSelector((state: any) => state.blogReducer);
    const { formLoader } = useSelector((state: any) => state.loaderReducer);

    useEffect(() => {
        if (blogDetails?.images && blogDetails.images.length > 0) {
            const transformedSections = blogDetails.images.map((img: any, index: number) => ({
                id: img.id || index + 1,
                imageUrl: img.imageUrl || "",
                title: img.title || "",
                description: img.description || ""
            }));
            setSections(transformedSections);
        }
    }, [blogDetails]);

    const handleAddSection = () => {
        setSections([...sections, { id: sections.length + 1, imageUrl: "", title: "", description: "" }]);
    };

    const handleRemoveSection = (id: number) => {
        setSections(sections.filter((section) => section.id !== id));
    };

    const handleChange = (index: number, field: string, value: string) => {
        const updatedSections = sections.map((section, i) =>
            i === index ? { ...section, [field]: value } : section
        );
        setSections(updatedSections);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!blogDetails?.tdcblogId) {
            Snackbar.show("Blog ID not found!", "error");
            return;
        }

        const payload = {
            tdcblogId: blogDetails.tdcblogId,
            images: sections.map(section => ({
                imageUrl: section.imageUrl,
                title: section.title,
                description: section.description
            }))
        };

        try {
            const response: any = await dispatch(
                saveBlogImages(apiEndPoint.GET_BLOG_SAVEORUPDATEIMAGES, payload)
            );

            if (response.status) {
                Snackbar.show("Blog sections updated successfully!", "success");
            } else {
                Snackbar.show(response.result || "Failed to update blog sections", "error");
            }
        } catch (error) {
            console.error("Error saving blog images:", error);
            Snackbar.show("An error occurred while saving blog sections", "error");
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            style={{ flex: 1, padding: "10px", border: "1px solid #ccc", borderRadius: "5px", marginLeft: "20px", position: "relative" }}
        >
            <h3 style={{ display: "inline-block" }}>Blog Section</h3>

            <button
                type="button"
                onClick={handleAddSection}
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    fontSize: "20px",
                    cursor: "pointer",
                }}
            >
                +
            </button>

            {sections.map((section, index) => (
                <div key={section.id} style={{ marginBottom: "15px", borderBottom: "1px solid #ddd", paddingBottom: "10px", position: "relative" }}>
                    <h4>Section {index + 1}</h4>

                    {sections.length > 1 && (
                        <button
                            type="button"
                            onClick={() => handleRemoveSection(section.id)}
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "5px",
                                background: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "25px",
                                height: "25px",
                                fontSize: "16px",
                                cursor: "pointer",
                            }}
                        >
                            -
                        </button>
                    )}

                    <input
                        type="text"
                        placeholder="Enter Image URL"
                        value={section.imageUrl}
                        onChange={(e) => handleChange(index, "imageUrl", e.target.value)}
                        style={{ width: "100%", padding: "8px", marginBottom: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                    <input
                        type="text"
                        placeholder="Enter Title"
                        value={section.title}
                        onChange={(e) => handleChange(index, "title", e.target.value)}
                        style={{ width: "100%", padding: "8px", marginTop: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                    <textarea
                        placeholder="Enter Description"
                        value={section.description}
                        onChange={(e) => handleChange(index, "description", e.target.value)}
                        style={{ width: "100%", padding: "8px", marginTop: "10px", borderRadius: "5px", border: "1px solid #ccc", height: "80px" }}
                    ></textarea>
                </div>
            ))}

            <SubmitBtn type="submit" disabled={formLoader} disabledColor={formLoader}>
                Update
            </SubmitBtn>

            <FormLoader visible={formLoader} />
        </form>
    );
};

export default BlogSection;