export const apiEndPoint = {
  GET_USER_DETAILS: "/userdata/id?userId",
  SIGN_UP_USER: "/userdata/signupUser",
  GET_COURSE_BY_ID: "/coursedata/courseadminById?courseId",
  GET_COURSE_SECTION_DETAILS: "/coursedata/section/courseSectionAdminByCourseId?courseId",
  GET_COURSE_SECTION_LIST: "/coursedata/section/courseSectionAdminByCourseId?courseId",
  SAVE_COURSE_SECTION: "/coursedata/section/saveCourseSection",
  SAVE_COURSE: "/coursedata/saveCourseAndCourseDetails",
  GET_COURSE_OVERVIEW_VIDEO: "/content/getCourseSectionArtifactByParent?parentArtifactId",
  UPLOAD_COURSE_OVERVIEW_VIDEO: "/content/uploadCourseDefaultVideo?courseId",
  UPLOAD_COURSE_OVERVIEW_LANGUAGE_VIDEO: "/content/uploadCourseDefaultVideoLanguage?courseId",
  UPLOAD_COURSE_SUB_SECTION_VIDEO: "/content/uploadCourseSectionVideo?courseSectionId",
  UPLOAD_COURSE_SUB_SECTION_LANGUAGE_VIDEO: "/content/uploadCourseSectionVideoLanguage?courseSectionId",
  UPLOAD_COURSE_SUB_SECTION_LANGUAGE_PDF: "/content/uploadMultiCourseSectionDocs?courseSectionId",
  UPLOAD_COURSE_SECTION_LANGUAGE_VIDEO: "/content/uploadCourseSectionVideoLanguage?courseSectionId",
  UPLOAD_COURSE_SECTION_IMAGE: "/content/uploadCourseSectionImage?courseSectionId",
  UPLOAD_COURSE_SECTION_PDF: "/content/uploadCourseSectionDoc?courseSectionId",
  UPDATE_USER: "/userdata/save",
  SAVE_COURSE_ASSIGNMENT: "/coursedata/assignment/saveCourseAssignment",
  SAVE_COURSE_QUESTIONS: "/coursedata/section/saveCourseQuestion",
  SAVE_COURSE_FINAL_ASSIGNMENT: "/coursedata/assignment/saveCourseFinalAssignment",
  GET_NEWS_BY_ID: "/notifi/news/id",
  SAVE_NEWS: "/notifi/news/save",
  UPLOAD_NEWS_PIC: "/notifi/blog/uploadNewsBlogPic?newsBlogId",
  GET_NEWS_ALL: "/notifi/newsqry/newsByCustomFilter?tdcuserId=0&fetchAllnews=Y",
  SAVE_BLOG: "/notifi/blog/save",
  GET_BLOGS_BY_CUSTOM_FILTER: "/notifi/blogqry/blogByCustomFilter",
  GET_BLOG_DETAILS: "/notifi/blog/id?blogId",
  GET_BLOG_SAVEORUPDATEIMAGES:'/notifi/blog/saveOrUpdateImages',
  SAVE_INVOICE: "/coursedata/docgen/downloadinvoiceByUserZip",
  SAVE_OFFER_BANNER: "/coursedata/cart/saveOfferCode",
  GET_INVOICE_BY_CUSTOM_FILTER: "",
  GET_INVOICE_DETAILS_BY_ID: "",
  DETETE_INVOICE: "",
  GET_INVOICE_ALL: "",
  GET_QUESTION_TAG_ALL: "/coursedata/section/questionTagAll",
  GET_QUESTION_TAG_BY_CUSTOM_FILTER: "/coursedata/section/exploreAdminQuestionTags",
  SAVE_QUESTION_TAG: "/coursedata/section/saveQuestionTag",
  GET_QUESTION_TAG_BY_ID: "/coursedata/section/questionTagById",
  DELETE_QUESTION_TAG: "/coursedata/section/deleteQuestionTagById",
  COURSE_SECTION_ADMIN_BY_SUBSECTION_ID: "/coursedata/section/courseSectionAdminBySubSectionId?subCourseSectionId",
  GET_PROMO_CODE_ALL: "/coursedata/cart/couponCodeAll",
  GET_PROMO_CODE_BY_CUSTOM_FILTER: "/coursedata/cart/exploreAdminCouponCodes",
  SAVE_PROMO_CODE: "/coursedata/cart/saveCouponCode",
  SAVE_MAP_PROMO_CODE: "/coursedata/cart/saveCouponCodeMap",
  PROMO_CODE_MAP_ALL: "/coursedata/cart/couponCodeMapAll",
  ADD_PROMO_CODE_MAP_LIST: "/coursedata/cart/addCouponCodeMapList",
  GET_REFERRAL_CODE_ALL: "/coursedata/cart/refCodeAll",
  PROMO_CODE_MAP_BY_COUPON_CODE: "/coursedata/cart/couponCodeMapByCouponId?couponCodeId",
  GET_ALL_COURSES: "/coursedata/courseqry/exploreCoursesByCustomFilterAdmin",
  GET_ALL_BUNDLES: "/coursedata/courseqry/exploreBundles",
  GET_ALL_USERS: "/userdata/listqry/exploreUsersAllByCustomFilter",
  SAVE_BUNDLE: "/coursedata/bundle/saveCourseBundle",
  UPLOAD_BUNDLE_IMAGE: "/coursedata/bundle/uploadBundlePic?itemType=BUNDLE&bundleId",
  UPLOAD_COURSE_IMAGE: "/coursedata/uploadCoursePic?courseId",
  GET_BUNDLE_BY_ID: "/coursedata/bundle/courseBundleid",
  SAVE_BUNDLE_COURSE_LIST: "/coursedata/bundle/saveCourseBundleItem",
  GET_BUNDLE_COURSE_BY_ID: "/coursedata/bundle/courseBundleItemid",
  GET_PROMO_CODE_DETAILS: "/coursedata/cart/couponCodeById?couponCodeId",
  GET_REFERRAL_CODE_BY_ID: "/coursedata/cart/refCodeById",
  GEN_REFERRAL_CODE: "/coursedata/cart/generateReferralCode",
  GET_REFERRAL_CODE_BY_CUSTOM_FILTER: "/coursedata/cart/exploreAdminReferralCodes",
  SAVE_REFERRAL_CODE: "/coursedata/cart/saveRefCode",
  GET_FACULTY_ALL: "/userdata/facultyqry/exploreFacultyAllByCustomFilter",
  SAVE_FACULTY: "/userdata/save",
  GET_FACULTY_BY_ID: "/userdata/id",
  GET_USER_DETAILS_BY_ID: "/userdata/details/userdetailsByUser",
  SAVE_USER_DETAILS: "/userdata/details/saveUserDetails",
  DELETE_FACULTY: "",
  UPLOAD__FACULTY_PROFILE_PIC: "/userdata/details/uploadProfilePic",
  UPLOAD__FACULTY_BANNER_PIC: "/userdata/details/uploadBannerPic",
  GET_COURSE_CATEGORY_ALL: "/coursedata/masterdata/coursecategoryall",
  GET_COURSE_LEVEL_ALL: "/coursedata/masterdata/courselevelall",
  GET_COURSE_VALIDITY: "/coursedata/masterdata/coursevalidityall",
  GET_COURSE_COUPONCODE: "/coursedata/masterdata/courseCouponCodeall",
  GET_COURSE_SECTION_ARTIFACT: "/content/getCourseSectionArtifact?artifactId",
  GET_COURSE_SECTION_ARTIFACTS: "/content/getCourseMultiSectionArtifacts?courseSectionId",
  GET_COURSE_SECTION_ARTIFACT_BY_PARENT: "/content/getCourseSectionArtifactByParent?parentArtifactId",
  GET_PURECHASAED_DATA: "/coursedata/b2bsales/b2bordersByUser?userId",
  BUY_B2B_SALES: "/coursedata/b2bsales/b2bsales?userId",
};
