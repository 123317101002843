export const reduxConstants = {
  AUTH_DETAILS: " AUTH_DETAILS",
  COURSE_BY_ID: "COURSE_BY_ID",
  SAVE_COURSE: "SAVE_COURSE",
  USER_BY_ID: "USER_BY_ID",
  B2B_GET_PURCHASED_DATA:"B2B_GET_PURCHASED_DATA",
  BUY_B2B_DATA:"BUY_B2B_DATA",
  DELETE_COURSE_BY_ID: "DELETE_COURSE_BY_ID",
  GET_COURSE_SECTION_DETAILS: "GET_COURSE_SECTION_DETAILS",
  COURSE_SECTION_LIST_ALL: "COURSE_SECTION_LIST_ALL",
  SAVE_COURSE_SECTION: "SAVE_COURSE_SECTION",
  SECTION_TITLE: "SECTION_TITLE",
  UPLOAD_COURSE_SUB_SECTION_VIDEO: "UPLOAD_COURSE_SUB_SECTION_VIDEO",
  UPLOAD_COURSE_SUB_SECTION_LANGUAGE_VIDEO: "UPLOAD_COURSE_SUB_SECTION_LANGUAGE_VIDEO",
  UPLOAD_COURSE_SUB_SECTION_LANGUAGE_PDF: "UPLOAD_COURSE_SUB_SECTION_LANGUAGE_PDF",
  SAVE_COURSE_SECTION_IMAGE: "SAVE_COURSE_SECTION_IMAGE",
  SAVE_COURSE_SECTION_PDF: "SAVE_COURSE_SECTION_PDF",
  SAVE_COURSE_ASSIGNMENT: "SAVE_COURSE_ASSIGNMENT",
  SAVE_COURSE_QUESTIONS: "SAVE_COURSE_QUESTIONS",
  GET_COURSE_OVERVIEW_VIDEO: "GET_COURSE_OVERVIEW_VIDEO",
  UPLOAD_COURSE_OVERVIEW_VIDEO: "UPLOAD_COURSE_OVERVIEW_VIDEO",
  INIT_COURSE: "INIT_COURSE",
  INIT_COURSE_LIST: "INIT_COURSE_LIST",
  INIT_ARTIFACT: "INIT_ARTIFACT",
  INIT_USER_LIST: "INIT_USER_LIST",
  INIT_BUNDLE: "INIT_BUNDLE",
  INIT_COURSE_SUB_SECTION: "INIT_COURSE_SUB_SECTION",
  COURSE_ERROR: "COURSE_ERROR",
  COURSE_VALIDITY_ALL: "COURSE_VALIDITY_ALL",
  COURSE_COUPON_CODE_ALL: "COURSE_COUPON_CODE_ALL",
  NEWS_ALL: "NEWS_ALL",
  ALL_USER_LIST: "ALL_USER_LIST",
  SAVE_NEWS: "SAVE_NEWS",
  NEWS_BY_ID: "NEWS_BY_ID",
  DELETE_NEWS_BY_ID: "DELETE_NEWS_BY_ID",
  INIT_NEWS: "INIT_NEWS",
  INIT_NEWS_LIST: "INIT_NEWS_LIST",
  BLOG_DETAILS_BY_ID: "BLOG_DETAILS_BY_ID",
  SAVE_BLOG_IMAGES: "SAVE_BLOG_IMAGES",
  SAVE_BLOG: "SAVE_BLOG",
  DELETE_BLOG_BY_ID: "DELETE_BLOG_BY_ID",
  DELETE_BUNDLE_BY_ID: "DELETE_BUNDLE_BY_ID",
  UPDATE_BLOG: "UPDATE_BLOG",
  INVOICE_DETAILS_BY_ID: "INVOICE_DETAILS_BY_ID",
  SAVE_INVOICE: "SAVE_INVOICE",
  DELETE_INVOICE_BY_ID: "DELETE_INVOICE_BY_ID",
  UPDATE_INVOICE: "UPDATE_INVOICE",
  SAVE_USER: "SAVE_USER",
  UPLOAD_BLOG_PIC: "UPLOAD_BLOG_PIC",
  ALL_BLOG_LIST: "ALL_BLOG_LIST",
  INIT_BLOG: "INIT_BLOG",
  INIT_BLOG_LIST: "INIT_BLOG_LIST",
  ALL_INVOICE_LIST: "ALL_INVOICE_LIST",
  INIT_INVOICE: "INIT_INVOICE",
  INIT_INVOICE_LIST: "INIT_INVOICE_LIST",
  INIT_QUESTION_TAG: "INIT_QUESTION_TAG",
  QUESTION_TAG_ALL: "QUESTION_TAG_ALL",
  SAVE_QUESTION_TAG: "SAVE_QUESTION_TAG",
  QUESTION_TAG_BY_ID: "QUESTION_TAG_BY_ID",
  DELETE_QUESTION_TAG: "DELETE_QUESTION_TAG",
  ALL_QUESTION_TAG_LIST: "ALL_QUESTION_TAG_LIST",
  SAVE_COURSE_SUB_SECTION: "SAVE_COURSE_SUB_SECTION",
  SAVE_PROMO_CODE: "SAVE_PROMO_CODE",
  UPDATE_PROMO_CODE: "UPDATE_PROMO_CODE",
  DELETE_PROMO_CODE: "DELETE_PROMO_CODE",
  SET_SELECTED_PROMO_CODE: "SET_SELECTED_PROMO_CODE",
  PROMO_CODE_ALL: "PROMO_CODE_ALL",
  ALL_PROMO_CODE_LIST: "ALL_PROMO_CODE_LIST",
  GET_PROMO_CODE: "GET_PROMO_CODE",
  INIT_FACULTY: "INIT_FACULTY",
  INIT_FACULTY_LIST: "INIT_FACULTY_LIST",
  GET_FACULTY_ALL: "GET_FACULTY_ALL",
  SET_FACULTY_LIST: "SET_FACULTY_LIST",
  SAVE_FACULTY: "SAVE_FACULTY",
  GET_FACULTY_BY_ID: "GET_FACULTY_BY_ID",
  GET_USER_DETAILS_BY_ID: "GET_USER_DETAILS_BY_ID",
  SAVE_USER_DETAILS: "SAVE_USER_DETAILS",
  CREATE_USER: "CREATE_USER",
  UPLOAD__FACULTY_PROFILE_PIC: "UPLOAD__FACULTY_PROFILE_PIC",
  UPLOAD__FACULTY_BANNER_PIC: "UPLOAD__FACULTY_BANNER_PIC",
  DELETE_FACULTY_BY_ID: "DELETE_FACULTY_BY_ID",
  SAVE_PROMO_CODE_MAP: "SAVE_PROMO_CODE_MAP",
  PROMO_CODE_MAP_ALL: "PROMO_CODE_MAP_ALL",
  ADD_PROMO_CODE_MAP_LIST: "ADD_PROMO_CODE_MAP_LIST",
  INIT_REFERRAL_CODE: "INIT_REFERRAL_CODE",
  REFERRAL_CODE_ALL: "REFERRAL_CODE_ALL",
  ALL_REFERRAL_CODE_LIST: "ALL_REFERRAL_CODE_LIST",
  DELETE_REFERRAL_CODE_BY_ID: "DELETE_REFERRAL_CODE_BY_ID",
  GET_ALL_COURSES: "GET_ALL_COURSES",
  GET_ALL_BUNDLES: "GET_ALL_BUNDLES",
  SAVE_BUNDLE: "SAVE_BUNDLE",
  GET_BUNDLE_BY_ID: "GET_BUNDLE_BY_ID",
  SAVE_BUNDLE_COURSE_LIST: "SAVE_BUNDLE_COURSE_LIST",
  GET_BUNDLE_COURSE_BY_ID: "GET_BUNDLE_COURSE_BY_ID",
  PROMO_CODE_DETAILS: "PROMO_CODE_DETAILS",
  INIT_PROMO_CODE: "INIT_PROMO_CODE",
  REFERRAL_CODE_BY_ID: "REFERRAL_CODE_BY_ID",
  GEN_REFERRAL_CODE: "GEN_REFERRAL_CODE",
  SAVE_REFERRAL_CODE: "SAVE_REFERRAL_CODE",
  COURSE_CATEGORY_ALL: "COURSE_CATEGORY_ALL",
  COURSE_LEVEL_ALL: "COURSE_LEVEL_ALL",
  FACULTY_ALL: "FACULTY_ALL",
  ENABLE_HEADER_TAB: "ENABLE_HEADER_TAB",
  COURSE_SECTION_ARTIFACT: "COURSE_SECTION_ARTIFACT",
  COURSE_SECTION_ARTIFACTS: "COURSE_SECTION_ARTIFACTS",
  COURSE_SECTION_ARTIFACT_BY_PARENT: "COURSE_SECTION_ARTIFACT_BY_PARENT",
  COURSE_SECTION_ARTIFACT_BY_PARENT_PDF: "COURSE_SECTION_ARTIFACT_BY_PARENT_PDF",
  HEADERBREADCRUMB_TITLE: "HEADERBREADCRUMB_TITLE",
  INIT_USER: "INIT_USER",
};

export const reduxLoader = {
  COURSE_LOADER: "COURSE_LOADER",
  CONTENT_LOADER: "CONTENT_LOADER",
  BUNDLE_LOADER: "BUNDLE_LOADER",
  NEWS_LOADER: "NEWS_LOADER",
  BLOG_LOADER: "BLOG_LOADER",
  USER_LOADER: "USER_LOADER",
  FACULTY_LOADER: "FACULTY_LOADER",
  PROMO_CODE_LOADER: "PROMO_CODE_LOADER",
  BLOG_PROFILE_LOADER: "BLOG_PROFILE_LOADER",
  QUESTION_TAG_LOADER: "QUESTION_TAG_LOADER",
  SEARCH_LOADER: "SEARCH_LOADER",
  FORM_LOADER: "FORM_LOADER",
  COURSE_MEDIA_LOADER: "COURSE_MEDIA_LOADER",
  SECTION_LOADER: "SECTION_LOADER",
  ARTIFACT_LOADER: "ARTIFACT_LOADER",
  ARTIFACT_LOADER_PDF: "ARTIFACT_LOADER_PDF",
  QUESTION_LOADER: "QUESTION_LOADER",
  OFFER_BANNER_LOADER: "OFFER_BANNER_LOADER",

};
