import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ImagePicker from "../../Components/FilePicker/ImagePicker";
import { useParams, useHistory } from "react-router-dom";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { getBlogDetails, uploadBlogPic } from "../../redux/action/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitBtn, BtnSection } from "../../Components/Card/Card";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  url?: any;
  blogReducer?: any;
}
const initialState: any = {
  picture: "",
};

const UploadPicture: React.FC<Props> = (props) => {
  const Snackbar = useSnackbar();

  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState<any>(initialState);
  let [updateProfile, setUpdateProfile] = useState(false);
  let [imagePreview, setImagePreview] = useState<any>(null);
  let [imagePath, setImagePath] = useState(null);
  const [imageAlert, setImageAlert] = useState(false);
  const classes = useStyles();
  const { blogDetails } = useSelector((state: Props) => state.blogReducer);
  const { formLoader } = useSelector((state: any) => state.loaderReducer);
  // const [fileSize, setFileSize] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateProfile) {
      createProfilePic(id);
    }
  };

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_BLOG_DETAILS}=${id}`;
      dispatch(getBlogDetails(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (blogDetails && id) {
      let data = blogDetails;
      setInputField({
        tdcblogId: id ? id : 0,
        picture: data.picture,
      });
      setImagePreview(data?.picture);
      let path = data?.picture?.split("/");
      path = path ? path[path.length - 1] : "";
      setImagePath(path);
    }
  }, [blogDetails, id]);

  var maxSize = 256;
  const handleProfileImage = (e) => {
    // setFileSize(false);
    setUpdateProfile(true);
    if (e.target.files && e.target.files[0]) {
      // var fsize = e.target.files[0].size / 1024;
      // if (fsize > maxSize) {
      //   setFileSize(true);
      //   return false;
      // }
      const formData = new FormData();
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setImagePath(e.target.files[0].name);
      formData.append("file", e.target.files[0]);
      setInputField({ ...inputField, picture: formData });
    }
  };

  const createProfilePic = async (blogId) => {
    let picture = inputField.picture;
    if (picture) {
      let uri = `${apiEndPoint.UPLOAD_NEWS_PIC}=${blogId}&itemType=Blog`;
      let response: any = await dispatch(uploadBlogPic(uri, picture));
      let { status, result } = response;
      if (status) {
        history.push("/home/blog");
        Snackbar.show("Image Uploaded Successfully!", "success");
      } else {
        Snackbar.show(result, "error");
      }
    }
  };

  return (
    <div>
      <form>
        <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
          <div style={{ height: 300, width: "50%", display: "flex", flex: 1 }}>
            <ImagePicker
              handlePickImage={handleProfileImage}
              image={imagePreview}
              imagePath={imagePath}
              buttonName="Upload Profile Pic"
            />
          </div>{" "}
          {/* {fileSize ? (
            <div style={{ color: "red" }}>
              {" "}
              You are not able to choose image because image size must be less then 256kb
            </div>
          ) : null} */}
        </Grid>
        <Grid>
          <BtnSection>
            {/* <SubmitBtn disabled={fileSize ? true : false} onClick={handleSubmit}> */}
            <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={handleSubmit}>
              Update
            </SubmitBtn>
          </BtnSection>
        </Grid>
      </form>
      <FormLoader visible={formLoader} />
    </div>
  );
};

export default UploadPicture;


